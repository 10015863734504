<template>
    <div class="row mt-4 mb-4">
        <div class="hover-blue cursor-pointer col-md-7 d-flex align-items-start align-items-md-center mb-4 px-2" @click="goBack">
            <div class="me-3 mt-2 mt-md-0">
                <icon-arrow :size="25" style="transform: rotate(180deg);" :color="'var(--dark-gray-200)'"/>
            </div>
            <div class="d-flex flex-column align-items-start ms-1">
                <h6 class="title-22 color-blue-700 mb-1">{{$t("account.my_orders")}}</h6>
                <h6 class="subtitle-16 mb-0">{{$t("account.description_my_orders")}}</h6>
            </div>
        </div>
        <div class="col-md-5 d-flex align-end mb-2 mb-sm-4 justify-content-center justify-content-md-end align-items-end">
            <input-search :placeholder="'account.search_by_id'" class="mb-0" style="max-width: 350px;" @search="getOrders" @keyupEnter="getOrders"
                @changeValue="(data)=>{search = data}"/>
        </div>
        <div class="col-md-12 mb-2">
            <hr class="mt-1">
        </div>
        <div class="col-4 col-xl-3 col-xxl-3 nav-container">
            <main-nav-tab :class="{'disabled-nav' : loading}" :options="optionsTab" :selectedOption="selectedTab" @select="(data)=>{selectedTab = data}" class="mt-2 m-0"/>
        </div>
        <div class="col-md-12">
            <div class="row" style="max-width: 1200px;">
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 d-flex align-items-center time-filter-container mt-2 mt-md-0">
                    <span class="me-3 weight-500 size-16 color-dark-gray-400">{{$t("account.show")}} </span>
                    <main-select class="ms-auto ms-md-0" :options="time" :selectedOption="filterDate"
                    :summaryRef="'time'" @clickHandler="(data) => {filterDate = data}"/>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 d-flex align-items-center branch-filter-container mt-2 mt-md-0 ps-xxl-3">
                    <span class="d-lg-none d-xl-block me-3 weight-500 size-16 color-dark-gray-400 text-limited">{{$t("account.orders_placed_in")}} </span>
                    <span class="d-none d-lg-block d-xl-none me-3 weight-500 size-16 color-dark-gray-400">{{$t("account.orders_placed")}} </span>
                    <main-select class="ms-auto ms-md-0" :options="filterBranches" :selectedOption="selectedBranch" @clickHandler="(data) => {selectedBranch = data}"
                    :summaryRef="'branch'"/>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-3 d-flex align-items-center state-filter-container mt-2 mt-lg-3 mt-xl-0">
                    <span class="me-3 weight-500 size-16 color-dark-gray-400">{{$t("account.order_status")}} </span>
                    <main-select :disabled="selectedTab.value == 'offline'" class="ms-auto ms-md-0" :options="filterStatus" :selectedOption="selectedStatus" @clickHandler="(data) => {selectedStatus = data}"
                    :summaryRef="'branch'"/>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-2 d-flex align-items-center state-filter-container mt-2 mt-lg-3 mt-xl-0">
                    <span class="me-3 weight-500 size-16 color-dark-gray-400">{{$t("account.orders")}} </span>
                    <main-select :disabled="selectedTab.value == 'offline'" class="ms-auto ms-md-0" :options="optionsShipment" :selectedOption="selectedOption" @clickHandler="(data) => {selectedOption = data}"
                    :summaryRef="'branch'"/>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="col-sm-12">
            <template v-if="checkDocumentId">
                <card-order-intranet v-for="order in orders.data" :key="order.id" :order="order" class="d-none d-md-block" @getOrders="reset(); getOrders();"/>
                <cardOrderIntranetMobile v-for="(order, index) in orders.data" :key="index" :order="order" class="mt-4 mt-lg-5 d-block d-md-none" @getOrders="reset(); getOrders();"/>
            </template>
            <template v-else>
                <card-order v-for="order in orders.data" :key="order.id" :order="order" class="d-none d-md-block" @getOrders="reset(); getOrders();"/>
                <card-order-mobile  v-for="(order, index) in orders.data" :key="index" :order="order" class="mt-4 mt-lg-5 d-block d-md-none" @getOrders="reset(); getOrders();"/>
            </template>
        </div>
        <without-results v-if="checkDataOrders" @click="reset" class="mt-5" description="account.no_item_matches" title="account.no_orders_found" titleButton="account.reset_filters">
            <template #main-icon>
                <div>
                    <icon-search-order :size="90"/>
                </div>
            </template>
            <template #button-icon>
                <icon-change :size="16" class="fill-white me-2" style="margin-top: -2px;"/>
            </template>
        </without-results>
        <template v-if="loading">
            <div>
                <lottie :options="defaultOptions" :height="250" :width="250"/>
            </div>
        </template>
        <main-pagination style="position: relative; top: 20px;" class="pagination d-flex align-items-center justify-content-center justify-content-md-end mt-4 mb-3 me-md-4 me-lg-5 pe-lg-3 pe-xl-0" :total="lastPage" :current="pageNumber" @setPage="getOrders"/>
    </div>
</template>
<script>
import Lottie from "vue-lottie";
import animationData from '../assets/animations/searching1.json';
import { mapGetters } from "vuex"
import InputSearch from '../../../common/components/inputs/InputSearch.vue'
import MainSelect from '../../../common/components/selects/MainSelect.vue'
import MainNavTab from '../../../common/components/tabs/MainNavTab.vue'
import CardOrder from '../components/orders/CardOrder.vue'
import {orderService} from "../../../modules/account/services/order-service";    
import CardOrderIntranet from "../components/orders/CardOrderIntranet.vue";  
import IconSearchOrder from '../assets/svg/iconSearchOrder.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'
import CardOrderMobile from '../components/orders/CardOrderMobile.vue'
import IconChange from '../../products/assets/svg/iconChange.vue';
import WithoutResults from '../../../common/components/modals/WithoutResults.vue';
import CardOrderIntranetMobile from "../components/orders/CardOrderIntranetMobile.vue";
import MainPagination from "../../../common/components/pagination/MainPagination.vue";

export default {
    name:"Orders",
    components:{
        MainNavTab,
        InputSearch,
        MainSelect,
        CardOrder,   
        IconSearchOrder,
        IconArrow,
        CardOrderMobile,
        Lottie,
        IconChange,
        WithoutResults,
        CardOrderIntranet,
        CardOrderIntranetMobile,
        MainPagination
    },
    computed:{
        ...mapGetters({
            branches: "branches/branches",
            branchSelected: "branches/branchSelected",
            orderStatus: "account/orderStatus",
        }),
        filterBranches(){
            const newObject = [];
            const options = this.branches;
            newObject.push( {
                value: 0,
                title: 'account.all_branches'
            });
            for(let option of options) {
                newObject.push( {
                    value: option.codplace,
                    title: this.$options.filters.textFormat(option.description)
                });
            }
            return newObject
        },
        checkDataOrders(){
            return !this.orders?.data?.length && !this.loading
        },
        checkDocumentId(){
            if(this.orders.data[0]?.document_id){
                return true
            } else {
                return false
            }
        },
        filterStatus(){
            let object = [];
            const options = this.orderStatus;
            object.push( {
                value: 0,
                title: 'account.all_the_states'
            });
            options.map(({id, name}) => {   
                object.push( {
                    value: id,
                    title: `cart.${name}`
                });
            })
            return object
        },
    },
    watch:{
        async selectedOption(val){
            this.resetValue = true
            this.selectedBranch = {value: 0}
            this.filterDate = {value: this.moment().subtract(6, 'month').format('YYYY-MM-DD'), title:'account.last_6_months'},
            this.selectedStatus = {value: 0}
            if(val.value == 'pick_up' || val.value == 'local_delivery' || val.value == 'nacional_delivery'){
                this.filterOption = val.value
            } else {
                this.filterOption = null
            }

            await this.getOrders()
            this.resetValue = false
        },
        async selectedTab(){
            this.selectedOption =  {value: 'allOrders', title: 'account.all_orders'}
        },
        async selectedBranch(){
            if(this.resetValue){
                return
            }
            await this.getOrders()
        },
        async selectedStatus(){
            if(this.resetValue){
                return
            }
            await this.getOrders()
        },
        async filterDate(){
            if(this.resetValue){
                return
            }
            await this.getOrders()

        }
    },
    data(){
        return {
            optionsTab: [
                {value: 'ongoingOrders', title: 'account.ongoing_orders'},
                {value: 'offline', title: 'account.offline_orders'},
            ],
            optionsShipment: [
                {value: 'pick_up', title: 'account.pick_up'},
                {value: 'local_delivery', title: 'account.delivery'},
                {value: 'nacional_delivery', title: 'account.national_shipments'},
                {value: 'allOrders', title: 'account.all_orders'}
            ],
            selectedTab: {value: 'ongoingOrders', title: 'account.ongoing_orders'},
            selectedOption: {value: 'allOrders', title: 'account.all_orders'},
            selectedBranch: {value: 0},
            time:[
                {value: this.moment().subtract(10, 'days').format('YYYY-MM-DD'), title:'account.last_10_days'},
                {value: this.moment().subtract(6, 'month').format('YYYY-MM-DD'), title:'account.last_6_months'},
                {value: this.moment().subtract(1, 'year').format('YYYY-MM-DD'), title:`${this.nowDate() - 1}`},
                {value: this.moment().subtract(2, 'year').format('YYYY-MM-DD'), title:`${this.nowDate() - 2}`}
            ],
            orders: [],
            filterOption: null,
            selectedStatus: {value: 0},
            search: '',
            filterDate: {value: this.moment().subtract(6, 'month').format('YYYY-MM-DD'), title:'account.last_6_months'},
            loading: false,
            defaultOptions: { animationData: animationData, loop: true },
            resetValue: false,
            pageNumber: 1,
            lastPage: 4
        }
    },
    methods:{
        nowDate(){
            const date = new Date().getFullYear();
            return date
        },
        goBack(){
            this.$router.go(-1)
        },
        async getOrders(page){
            let searchDocumenId = null;
            let searchId = null;
            let searchGlobal=null;
            if(this.search === ''){
                this.search = null
            }else if(this.search != null && this.search != ''){
                if(this.search.slice(0,2) === 'QT' || this.search.slice(0,2) === 'qt' || this.search.slice(0,2) === 'Qt'){
                    searchDocumenId = this.search
                }else if(isFinite(this.search) && this.search.length === 4){
                    searchId = this.search
                }else{
                    searchGlobal= this.search
                }
            }
            try {
                this.loading = true
                if(this.selectedTab.value == 'offline'){
                    this.pageNumber = page
                    this.orders = await orderService.getOrdersIntranet(this.filterDate.value, this.selectedBranch?.value, this.selectedStatus.value, this.filterOption, searchGlobal, searchId, searchDocumenId, page || 1);
                    this.lastPage = this.orders.last_page
                    this.pageNumber = this.orders.current_page
                } else {
                    this.pageNumber = page
                    this.orders = await orderService.getOrders(this.filterDate.value, this.selectedBranch?.value, this.selectedStatus.value, this.filterOption, searchGlobal, searchId, searchDocumenId, page || 1);
                    this.lastPage = this.orders.last_page
                    this.pageNumber = this.orders.current_page
                }
            }catch (e) {
                console.log(e)
            }finally {
                this.loading = false
                this.search = ''

            }
        },
        async reset(){
            this.lastPage = 4
            this.pageNumber = 1
            this.resetValue = true
            this.selectedTab = {value: 'ongoingOrders', title: 'account.ongoing_orders'}
            this.selectedOption = {value: 'allOrders', title: 'account.all_orders'}
            this.filterOption = 0
            this.selectedBranch = {value: 0}
            this.filterDate = {value: this.moment().subtract(10, 'days').format('YYYY-MM-DD'), title: 'account.last_10_days'}
            this.selectedStatus = {value: 0}
            await this.getOrders()
            this.resetValue = false
        }
    },
    async created(){
        try {
            this.loading = true
            await this.$store.dispatch("account/getOrderStatus")
            await this.getOrders()
        } catch (error) {
            console.log(error);
        }finally{
            this.loading = false        
        }
    }
}
</script>


<style scoped>
    .nav::v-deep > .nav-item > .nav-link {
        margin-right: 35px !important;
    }
    .select-container::v-deep > div > span {
        font-size: 13px !important;
    }
    .hover-blue svg {
        transition: var(--transition-1);
    }
    .hover-blue:hover svg{
        fill: var(--blue-600);
    }
    .nav-container {
        margin-bottom: 15px;    
        width: 308px;
    }
    .select-container {
        min-width: 190px !important;
    }

    @media (min-width : 768px) {
        .nav-container {
            margin-bottom: 20px;
            width: 335px;    
        }
        .card-order {
            margin-top: 45px;
        }
        .select-container {
            min-width: 170px !important;
        }
    }
    @media (min-width : 992px) {
        .nav-container {
            margin-bottom: 30px;    
        }
        .card-order {
            margin-top: 45px;
        }
        .time-filter-container {
            max-width: 325px;
        }
    }
    @media (min-width : 1200px) {
        .time-filter-container {
            max-width: 305px;
        }
        .card-order {
            margin-top: 50px;
        }
    }
    @media (min-width : 1400px) {
        .time-filter-container {
            max-width: 325px;
        }
    }
    .disabled-nav{
        pointer-events: none;
    }
</style>
