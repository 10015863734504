<template>
    <div class="input-group input-search">
        <input type="text" class="form-control" :placeholder="$t(placeholder)" v-model="modelValue" @keyup.enter="$emit('keyupEnter')" :aria-label="placeholder" v-mask="mask">
        <main-button :icon="true" :title="''" @click="$emit('search')">
            <div  v-if="!loading">
                <icon-search :color="'#fff'" v-if="!icon"/>
                <slot v-else></slot>
            </div>
            <half-circle-spinner :animation-duration="1000" :size="20" color="#fff" v-else/>
        </main-button>
    </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
import IconSearch from '../../svg/iconSearch.vue'
import MainButton from '../buttons/MainButton.vue'
export default {
    name: "InputSearch",
    props:{
        placeholder:{
            type: String
        },
        mask:{
            type: String
        },
        value:{
            type:[String , Number, null],
        },
        loading:{
            type: Boolean,
            default: false
        },
        icon:{
            type: Boolean
        }
    },
    data() {
        return {
            modelValue: this.value
        }
    },
    watch:{
        value(val){
            this.modelValue = val
            this.$emit('changeValue', val)
        },
        modelValue(val){
            this.$emit('changeValue', val)
        }
    },
    components:{
        IconSearch,
        MainButton,
        HalfCircleSpinner
    }
}
</script>

<style scoped>
    .input-group{
        max-height: 35px;
    }
    input {
        border-color: var(--gray-50) !important;
        border-radius: 7px !important;
        border: 1px solid;
        width: 100%;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        max-height: 35px;

    }
    input:focus{
        box-shadow: none;
    }
    input::placeholder{
        font-size: 14px;
    }
</style>>

