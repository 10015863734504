<template>
    <div>
        <div class="row m-0 card-order-mobile">
            <div class="pt-2 d-flex align-items-start flex-column">
                <h5 class="m-0 title-18 weight-600"><b class="color-main-red weight-600">{{ $t("account.order_id") }}</b>
                    #{{ order.document_id }}</h5>
                <div class="d-flex align-items-center">
                    <div class="bg-transparent p-0 d-flex align-items-center me-2">
                        <icon-circle :size="10" />
                    </div>
                    <span class="color-dark-gray-300 subtitle-15">{{ $t("account.estimated_date") }}
                        <b>{{ order.created_at.slice(8, 10) }} {{ formatMonth(order.created_at.slice(5, 7)) }}
                            {{ order.created_at.slice(0, 4) }}</b> </span>
                </div>
            </div>
            <div class="d-flex subtitle-14 justify-content-around justify-content-md-between mt-3 mb-4">
                <a href="#" @click.prevent="print(order)">{{ $t("account.print") }}</a>
                <span class="me-2 ms-2 subtitle-14 color-dark-gray-200">|</span>
                <a href="#" @click.prevent="buyAgain(order.order_details)">{{ $t("account.buy_again") }} </a>
            </div>
            <div class="col-md-12 mt-3" v-if="isOrderDetails">
                <h1 class="title-18 weight-600">{{ $t("account.general_information") }}</h1>
            </div>
            <div v-if="!isOrderDetails" class="col-5 justify-content-center">
                <router-link :to="`invoices/${order.document_id}`">
                    <wrapper-image class="wrapper-img mx-auto" :images="order.order_details" :maxWidth="120"
                        :wrapperHeight="135" :wrapperWidth="120" :imgHeight="65" />
                </router-link>
            </div>
            <div class="col-7 d-flex flex-column align-items-start py-2 mx-auto">
                <div class="d-flex align-items-center">
                    <span class="title-14 mb-0 me-2">Pedido</span>
                    <span class="color-dark-gray-300 subtitle-14">{{ order.created_at.slice(8, 10) }}
                        {{ formatMonth(order.created_at.slice(5, 7)) }} {{ order.created_at.slice(0, 4) }}</span>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="title-14 mb-0 me-2">{{ $t("account.amount") }}</span>
                    <span class="subtitle-14 color-dark-gray-300">{{ order.amount | moneyFormat }}</span>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <span class="title-14 mb-0 me-2">{{ $t("account.quantity") }}</span>
                    <span class="color-dark-gray-300 subtitle-14">{{ amountProduct }}</span>
                </div>
                <!-- <order-status :orderStatus="order.order_status.name" @circleStatus="getCircleStatus" class="mt-2" /> -->
            </div>
            <div class="col-12 col-lg-3 d-flex justify-content-between justify-content-md-center align-items-center mt-3 mt-sm-0 me-4 py-2"
                v-if="!isOrderDetails">
                <router-link :to="`invoices/${order.document_id}`" style="width: 140px;" class="me-4">
                    <second-button :title="'account.details'" :id="'details'" class="me-3 w-100" />
                </router-link>
                <main-button :title="'account.track_order'" @click="showModalTrack = true" style="width: 140px;" />
                <!-- <main-button :title="'account.track_order'" @click="showModalTrack = true" style="width: 140px;"/> -->
            </div>
        </div>
        <confirm-cancel-order @confirmDelete="cancelOrder" @closeModal="showCancel = false" v-if="showCancel"
            :orderId="order.order_token_id" />
        <main-modal :title="''" @closeModal="showModalTrack = false" v-if="showModalTrack">
            <track-order :orderId="order.order_document_id" :stateOrder="order.order_status_id" :order="order"
                :filterStateOrderById="order.order_status" />
        </main-modal>
    </div>
</template>

<script>
import { productMixin } from "../../../products/helpers/mixin/product-mixin";
import { mapGetters } from "vuex"
import SecondButton from '../../../../common/components/buttons/SecondButton.vue';
import MainButton from '../../../../common/components/buttons/MainButton.vue';
import WrapperImage from '../../../../common/components/images/WrapperImage.vue';
import TrackOrder from './TrackOrder.vue';
import ConfirmCancelOrder from './ConfirmCancelOrder.vue';
// import OrderStatus from "./OrderStatus.vue"
import MainModal from '../../../../common/components/modals/MainModal.vue';
import IconCircle from '../../../../common/svg/iconCircle.vue';
import { http } from "../../../../common/services/web-backend";

export default {
    name: "CardOrderIntranetMobile",
    props: {
        order: {
            type: Object,
            require: true
        },
        borderOrderList: {
            type: Boolean,
            default: true
        },
        isOrderDetails: {
            type: Boolean,
            default: false
        },
    },
    components: {
        SecondButton,
        MainButton,
        WrapperImage,
        TrackOrder,
        ConfirmCancelOrder,
        // OrderStatus,
        MainModal,
        IconCircle,
    },
    mixins: [productMixin],
    computed: {
        ...mapGetters({
            orderStatus: "account/orderStatus",
            configurations: "common/configurations"
        }),
        filterStateOrderById() {
            const statusFilter = this.orderStatus.filter((state) => {
                return state.id === this.order.order_status_id
            })
            return statusFilter[0]
        },
        amountProduct() {
            let total = 0
            for (let i = 0; i < this.order.order_details.length; i++) {
                total = total + 1
            }
            return total
        },
    },
    data() {
        return {
            showDatails: false,
            showInvoice: false,
            showCancel: false,
            loading: false,
            error: null,
            showModalTrack: false,
            circleStatus: '',
        }
    },
    created() {
    },

    methods: {
        getCircleStatus(e) {
            this.circleStatus = e
        },
        formatMonth(month) {
            switch (month) {
                case "01":
                    return 'Enero'
                case "02":
                    return 'Febrero'
                case "03":
                    return 'Marzo'
                case "04":
                    return 'Abril'
                case "05":
                    return 'Mayo'
                case "06":
                    return 'Junio'
                case "07":
                    return 'Julio'
                case "08":
                    return 'Agosto'
                case "09":
                    return 'Septiembre'
                case "10":
                    return 'Octubre'
                case "11":
                    return 'Noviembre'
                case "12":
                    return 'Diciembre'
            }
        },
        isDeliveryService(orderPrimary) {
            return orderPrimary.codplace !== null
        },
        async getOrder(object_id) {
            try {
                this.loadingCarrier = true
                let response = await http.get('orders/' + object_id)
                this.carrierData = response.data.data
            } catch (error) {
                console.log(error);
            } finally {
                this.loadingCarrier = false
                this.showModalTrack = true
            }
        },
        findPlace(codplace) {
            return this.$store.getters['places/places'].find(place => place.codplace === codplace)

        },
        rating(data) {
            this.order['order_details'].map(detail => {
                if (detail.product.codarticulo === data.codarticulo) {
                    detail.product['my_rating'] = { rated: data.rated };
                }
            })
        },
        async buyAgain(products) {
            for (let i = 0; i < products.length; i++) {
                const product = products[i];
                await this.$store.dispatch("cart/updateQuantity", { product: product.product, quantity: product.quantity });
            }
            let data = { type: 'addProduct', title: 'account.products_added_to_cart', show: true, quantity: products.quantity, img: this.getImages(products[0].product.imageGallery), product: products[0].product }
            this.$store.commit("common/SET_NOTIFY", data)
        },
        disabledCancelOrder() {
            if (this.configurations.general.web_page.disable_cancel_order) {
                const warning = {
                    show: true,
                    title: this.$t('checkout.notice'),
                    description: this.$t('checkout.warning_cancel_info'),
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', warning)
            } else {
                this.showCancel = true
            }
        },
        async cancelOrder() {
            try {
                await this.$store.dispatch('account/deleteOrder', this.order.id);
                this.showCancel = false
            } catch (error) {
                console.log(error);
            }
        },
        async print() {
            await this.$htmlToPaper('print-invoice');
        }
    },
}
</script>

<style scoped>
.card-order-mobile {
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 3%;
}

a {
    font-size: 14px;
    color: var(--blue-600);
}

.wrapper-img::v-deep>div>.img-4 {
    border-radius: 4px;
}
</style>
