import { render, staticRenderFns } from "./InputSearch.vue?vue&type=template&id=4a52074b&scoped=true"
import script from "./InputSearch.vue?vue&type=script&lang=js"
export * from "./InputSearch.vue?vue&type=script&lang=js"
import style0 from "./InputSearch.vue?vue&type=style&index=0&id=4a52074b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a52074b",
  null
  
)

export default component.exports